import { FC } from 'react'
import { ButtonProps } from './button.types'
import clsx from 'clsx'
import styles from './button.module.scss'

export const Button: FC<ButtonProps> = ({
  iconLeft,
  iconRight,
  size = 'standart',
  variant = 'fill',
  danger = false,
  className,
  children,
  ...props
}) => {
  const buttonClasses = clsx(
    styles['button'],
    styles[`button_${size}`],
    styles[`button_${size}_${variant}`],
    className,
    danger ? styles[`button_${size}_${variant}_danger`] : null,
  )

  return (
    <button className={buttonClasses} {...props}>
      {iconLeft}
      {children}
      {iconRight}
    </button>
  )
}
