import { FC, useState } from 'react'

import { SavedCards } from '@/entities/saved-card'
import { UserData } from '@/widgets/user-data'

import styles from './payment-page.module.scss'
import { PaymentSessionTimer } from '@/entities/payment-session-timer'
import { useLanguage } from '@/shared/contexts/language-context'
import { Typography } from '@/shared/components/typography'
import { useMediaContext } from '@/shared/contexts/media-context/media-context'
import { usePaymentContext } from '@/shared/contexts/payment-context'

export const PaymentPage: FC = () => {
  const { getField } = useLanguage()
  const { selectedFields } = usePaymentContext()
  const [isMoreOpen, setMoreOpen] = useState(false)
  const { isScreenSm } = useMediaContext()

  return selectedFields.length ? (
    <div className={styles['payment']}>
      <div className={styles['payment-body']}>
        <div className={styles['header']}>
          <Typography
            tag="span"
            fontSize={isScreenSm ? '20' : '35'}
            lineHeight="45"
            letterSpacing="micro"
            color="white-100"
          >
            {getField('payment_method_title')}
          </Typography>
          <PaymentSessionTimer />
        </div>
        <div className={styles['payment-form']}>
          <SavedCards
            isMoreOpen={isMoreOpen}
            onMoreClose={() => setMoreOpen(false)}
            onMoreOpen={() => setMoreOpen(true)}
          />
          <UserData isOpen={!isMoreOpen} />
        </div>
      </div>
    </div>
  ) : (
    <UserData isOpen={!isMoreOpen} />
  )
}
