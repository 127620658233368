import { useRef, useState } from 'react'
import { BACKEND_SSE } from '@/shared/constants/api'

export const useSSE = () => {
  const [eventSource, setEventSource] = useState<EventSource | null>(null)
  const eventSourceRef = useRef<EventSource | null>(null)

  const create = (order: string) => {
    const url = `${BACKEND_SSE}/sse/sse/${order}`

    eventSourceRef.current = new EventSource(url)
    setEventSource(eventSourceRef.current)

    eventSourceRef.current.onopen = () => {
      console.info('SSE connection is open.')
    }

    eventSourceRef.current.onerror = (err) => {
      console.error(err, 'SSE connection failed.')
      eventSourceRef.current = null
      setEventSource(eventSourceRef.current)
    }
  }

  const close = () => {
    eventSourceRef.current?.close()
    eventSourceRef.current = null
    setEventSource(eventSourceRef.current)
  }

  return { create, close, eventSource, eventSourceRef } as const
}
