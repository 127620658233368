import type { FC } from 'react'

import { useEffect } from 'react'
import { useNavigate } from 'react-router'

import { TOKEN_PAGE } from '@/shared/constants/routes'
import { useSearchParams } from '@/shared/contexts/search-params-context'
import { usePaymentTokenContext } from '@/shared/contexts/token-context'
import { SearchParams } from '@/shared/constants/search-params'
import { matchPaymentPath } from '@/shared/utils/match-payment-path'

export const CustomRedirect: FC = () => {
  const navigate = useNavigate()
  const { params } = useSearchParams()
  const { data, token } = usePaymentTokenContext()

  if (process.env.REACT_APP_SETTINGS_PAGE) {
    if (!token) {
      navigate({
        pathname: TOKEN_PAGE,
        search: new URLSearchParams(params).toString(),
      })
    }
  }

  useEffect(() => {
    if (token && data) {
      const { pathname, searchParams } = matchPaymentPath(data)
      navigate({
        pathname,
        search: new URLSearchParams({
          [SearchParams.Token]: token,
          ...searchParams,
        }).toString(),
      })
    }
  }, [data])

  return <></>
}
