export enum ParentEvents {
  FullReload = 'full_reload',

  SuccessPageOpen = 'success_page_open',
  SuccessBackButtonClick = 'success_back_button_click',

  InterruptPageOpen = 'interrupt_page_open',
  InterruptRetryButtonClick = 'interrupt_retry_button_click',

  FailedPageOpen = 'failed_page_open',
  FailedRetryButtonClick = 'failed_retry_button_click',
}
