import { ParentEvents } from '../constants/parent-events'

export const postEventToParent = ({
  event,
  data,
}: {
  event: ParentEvents
  data?: unknown
}) => {
  try {
    window.parent.postMessage(
      {
        event,
        data,
      },
      // window.location.origin,
      '*',
    )
  } catch (err) {
    console.error(err)
  }
}
