import type { FC } from 'react'

import { useState } from 'react'

import { UserPaymentMethodPublic, apiService } from '@/shared/api'
import { UserPaymentMethodPublicWithData } from '@/shared/contexts/payment-context'
import { getCardType } from '@/shared/hooks/use-input-mask'
import { useLanguage } from '@/shared/contexts/language-context'
import { SavedCardManagement } from '@/shared/components/saved-card-management'
import { useCardsTokenContext } from '@/shared/contexts/token-context'
import { useManagementContext } from '@/shared/contexts/management-cards-context'
import { Headers } from '@/shared/constants/headers'
import { PaymentSystemTypes } from '@/shared/components/saved-card-display/saved-card-display.types'

import { ReactComponent as CardDobule } from '@/shared/icons/card-double.svg'

import styles from './management-cards.module.scss'
import classnames from 'classnames'
import { Typography } from '@/shared/components/typography'
import { MANAGEMENT_CARDS_PAGE_DATA_ID } from '@/shared/constants/test/data-testid'

export const ManagementCards: FC = () => {
  const { getField } = useLanguage()
  const { token } = useCardsTokenContext()
  const { savedCards, getCards } = useManagementContext()

  const [activeCard, setActiveCard] = useState<
    UserPaymentMethodPublic | null | UserPaymentMethodPublicWithData
  >(null)

  // вынести запрос в management-context
  const handleDeleteCard = (itemID: string) => {
    apiService.method
      .deleteMethodMethodMethodIdDelete(itemID, {
        headers: {
          [Headers.Token]: token,
        },
      })
      .then(() => {
        setActiveCard(null)
        getCards()
      })
      .catch((e) => {
        console.error(e)
      })
  }

  const handleChooseCard = (item: UserPaymentMethodPublic) => {
    setActiveCard(item)
  }

  return (
    <div
      className={classnames(styles['management'])}
      onClick={() => setActiveCard(null)}
    >
      <div
        className={styles['body']}
        data-testid={MANAGEMENT_CARDS_PAGE_DATA_ID.CARDS_CONTAINER}
      >
        {savedCards?.length !== 0 ? (
          savedCards?.map((item) => (
            <SavedCardManagement
              className={styles['cards']}
              paymentSystem={
                getCardType(
                  (item as UserPaymentMethodPublicWithData).data
                    .card_number_first6,
                ) as PaymentSystemTypes
              }
              confirmText={getField('cards_control_confirm_button')}
              tooltipText={getField('cards_сontrol_tooltip_name')}
              isActive={item.id === activeCard?.id}
              cardNumber={
                (item as UserPaymentMethodPublicWithData).data.card_number_last4
              }
              onConfirmDelete={(e) => {
                e.stopPropagation()
                handleDeleteCard(item.id)
              }}
              onDelete={(e) => {
                e.stopPropagation()
                handleChooseCard(item)
              }}
              data-testid={MANAGEMENT_CARDS_PAGE_DATA_ID.SAVED_CARD}
            />
          ))
        ) : (
          <div
            className={styles['no-cards-wrapper']}
            data-testid={MANAGEMENT_CARDS_PAGE_DATA_ID.NO_SAVED_CARD_CONTAINER}
          >
            <CardDobule />
            <Typography
              className={styles['typography']}
              color="white-100"
              lineHeight="25"
              fontSize="20"
            >
              {getField('cardmanagement_nocard_title')}
            </Typography>
            <Typography
              className={styles['typography']}
              color="white-50"
              lineHeight="15"
              fontSize="11"
            >
              {getField('cardmanagement_nocard_label')}
            </Typography>
          </div>
        )}
      </div>
    </div>
  )
}
