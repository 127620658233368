import { FC, ReactNode } from 'react'
import { SavedCardManagementProps } from './saved-card-management.types'
import { ReactComponent as MirWhiteIcon } from '@/shared/icons/mir-white-icon.svg'
import { ReactComponent as MirGrayIcon } from '@/shared/icons/mir-gray-icon.svg'
import { ReactComponent as VisaWhiteIcon } from '@/shared/icons/visa-white-icon.svg'
import { ReactComponent as VisaGrayIcon } from '@/shared/icons/visa-gray-icon.svg'
import { ReactComponent as MastercardWhiteIcon } from '@/shared/icons/mastercard-white-icon.svg'
import { ReactComponent as MastercardGrayIcon } from '@/shared/icons/mastercard-gray-icon.svg'
import { ReactComponent as EllipseWhiteIcon } from '@/shared/icons/ellipse-white-icon.svg'
import { ReactComponent as EllipseGrayIcon } from '@/shared/icons/ellipse-gray-icon.svg'
import { ReactComponent as CheckRedIcon } from '@/shared/icons/check-red-icon.svg'
import Tippy from '@tippyjs/react'

import clsx from 'clsx'
import styles from './saved-card-management.module.scss'
import { Typography } from '../typography'
import { MANAGEMENT_CARDS_PAGE_DATA_ID } from '@/shared/constants/test/data-testid'

export const SavedCardManagement: FC<SavedCardManagementProps> = ({
  isActive = false,
  onConfirmDelete = () => {},
  onDelete = () => {},
  cardNumber,
  confirmText,
  tooltipText,
  paymentSystem,
  className,
  ...props
}) => {
  const containerClasses = clsx(styles['container'], className)
  const contentClasses = clsx(
    styles['content'],
    isActive && styles['content_active'],
  )

  let iconMatcher: Record<string, ReactNode>

  isActive
    ? (iconMatcher = {
        mir: <MirGrayIcon />,
        visa: <VisaGrayIcon />,
        mastercard: <MastercardGrayIcon />,
      })
    : (iconMatcher = {
        mir: <MirWhiteIcon />,
        visa: <VisaWhiteIcon />,
        mastercard: <MastercardWhiteIcon />,
      })

  return (
    <div className={containerClasses} {...props}>
      <div className={contentClasses}>
        <div className={styles['info-slot']}>
          <div className={styles['info-slot-left']}>
            <div className={styles['info-slot-left-system']}>
              {iconMatcher[paymentSystem as string]}
            </div>
            <div className={styles['info-slot-left-card-info']}>
              {isActive ? <EllipseGrayIcon /> : <EllipseWhiteIcon />}
              {cardNumber}
            </div>
          </div>
          <div>
            {isActive ? (
              <div
                data-testid={
                  MANAGEMENT_CARDS_PAGE_DATA_ID.CONFIRM_DELETE_CARD_BTN
                }
                className={styles['info-slot-right-confirm']}
                onClick={onConfirmDelete}
              >
                <Typography
                  tag="span"
                  fontSize="13"
                  lineHeight="20"
                  color="base-alizarin-crimson"
                >
                  {confirmText}
                </Typography>
                <CheckRedIcon />
              </div>
            ) : (
              <Tippy
                content={tooltipText}
                interactive={true}
                interactiveBorder={20}
                delay={90}
                offset={[0, 25]}
                arrow={true}
                className={styles['custom-tooltip']}
                placement="right"
              >
                <div
                  data-testid={MANAGEMENT_CARDS_PAGE_DATA_ID.DELETE_CARD_BTN}
                  onClick={onDelete}
                  className={styles['info-slot-right-delete']}
                ></div>
              </Tippy>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}
